import deepMerge from '@fastify/deepmerge'
import AsyncStorage from '@react-native-async-storage/async-storage'
import type { QueryFunctionContext, UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import type { AppConfig } from '@vatom/models'
import { businessDataSchema } from '@vatom/models'
import { allSettled, isFulfilled, isRejected, useConfigState } from '@vatom/sdk/react'
import type { PageConfig } from '@vatom/wallet-sdk'
import { useVatomWalletSdkStore, VatomWallet } from '@vatom/wallet-sdk'
import { keyBy } from 'lodash-es'
import type { SafeParseReturnType } from 'zod'

import { getRootStore } from '../../../SDKProvider'

import type { BusinessData, BusinessReference } from './types'

export const businessQueryKeys = {
  business: [{ scope: 'business' }] as const,
  getBusiness: (businessIdOrName: string, publicOnly?: boolean) =>
    [{ ...businessQueryKeys.business[0], businessIdOrName, publicOnly }] as const,
  businesses: [{ scope: 'businesses' }] as const,
  getBusinesses: (name: string, context?: string, join?: boolean) =>
    [{ ...businessQueryKeys.businesses[0], name, context, join }] as const,
  businessesByIds: [{ scope: 'businessesByIds' }] as const,
  getBusinessesByIds: (ids: string[]) =>
    [{ ...businessQueryKeys.businessesByIds[0], ids }] as const,
  businessesUser: [{ scope: 'businessesUser' }] as const,
  businessesInventoryUser: [{ scope: 'businessesInventoryUser' }] as const,
  businessCommunities: [{ scope: 'communities' }] as const,
  getBusinessCommunities: (businessId: string) =>
    [{ ...businessQueryKeys.businessCommunities[0], businessId }] as const
}

export const fetchBusiness = async ({
  queryKey: [{ businessIdOrName, publicOnly }]
}: QueryFunctionContext<ReturnType<(typeof businessQueryKeys)['getBusiness']>>) => {
  const apiInstance = getRootStore().service.studio
  const response = await apiInstance?.get(`/b/${businessIdOrName}?publicOnly=${publicOnly}`)
  return response?.data
}

export const fetchBusinesses = async ({
  queryKey: [{ name, context, join }]
}: QueryFunctionContext<ReturnType<(typeof businessQueryKeys)['getBusinesses']>>) => {
  let url = `/b?name=${name}`

  const apiInstance = getRootStore().service.studio

  const redirectContext = await AsyncStorage.getItem('REDIRECT_CONTEXT')

  if (context || redirectContext) {
    url = `${url}&context=${context || redirectContext}`
  }
  if (join) {
    url = `${url}&join=${join}`
    AsyncStorage.removeItem('REDIRECT_CONTEXT')
  }

  const response = await apiInstance?.get<BusinessData[]>(url)
  return response?.data
}

type UseBusinessOptions<T> = Omit<
  UseQueryOptions<BusinessData, unknown, T, ReturnType<typeof businessQueryKeys.getBusiness>>,
  'queryKey' | 'queryFn'
>

const getCustomDomainConfigSelector = (appConfig: AppConfig) => (data: BusinessData) => {
  const businessProfileFromWalletConfig = appConfig.businessProfileOverride
  const strategy = appConfig?.businessProfileOverrideStrategy
  const shouldApply =
    businessProfileFromWalletConfig &&
    appConfig?.isBusinessLocked &&
    appConfig.businessId === data.id

  if (!shouldApply) {
    return data
  }
  let result

  if (strategy === 'deep-merge') {
    const dp = deepMerge()
    const merged = dp(data, businessProfileFromWalletConfig)
    result = merged
  }

  if (strategy === 'override') {
    result = businessProfileFromWalletConfig
  }

  if (strategy === 'shallow-merge') {
    result = { ...data, ...businessProfileFromWalletConfig }
  }

  const parsedResult = businessDataSchema.safeParse(result)
  if (parsedResult && !parsedResult.success) {
    console.warn('Error handling business profile override:', {
      error: parsedResult.error,
      businessProfileFromWalletConfig,
      strategy,
      businessProfileFromStudio: data
    })
    return result as BusinessData
  }

  return result as BusinessData
}

// Memoize the business config selector to avoid re-creating the result on every render
const businessConfigMemo = new Map<string, ReturnType<typeof getCustomDomainConfigSelector>>()
const getBusinessConfigWithMemo = (appConfig: AppConfig) => {
  const businessConfigSelector = businessConfigMemo.get(appConfig.businessId)
  if (businessConfigSelector) {
    return businessConfigSelector
  }
  const businessConfig = getCustomDomainConfigSelector(appConfig)
  businessConfigMemo.set(appConfig.businessId, businessConfig)
  return businessConfig
}

export const getBusinessSelector =
  <T = BusinessData>(appConfig: AppConfig) =>
  (selector?: (data: BusinessData) => T) =>
  (data: BusinessData) => {
    const customDomainConfigSelector = getBusinessConfigWithMemo(appConfig)
    const result = customDomainConfigSelector(data)
    if (selector) {
      return selector(getPageConfig(result))
    }
    return getPageConfig(result)
  }

export const useBusiness = <T = BusinessData>(
  props: BusinessReference,
  options?: UseBusinessOptions<T>,
  publicOnly?: boolean
) => {
  const resultByName = useBusinessSearch(props, !props?.businessId)
  const foundByName = resultByName?.data?.[0]?.id
  const businessId = foundByName ?? props?.businessId
  const appConfig = useConfigState()

  const query = useQuery({
    queryKey: businessQueryKeys.getBusiness(businessId ?? '', publicOnly ? publicOnly : true),
    queryFn: fetchBusiness,
    select: getBusinessSelector<T>(appConfig.data)(options?.select),
    enabled: !!businessId
  })

  return query
}

export const useBusinessSearch = (props: BusinessReference, enabled = true) => {
  const businessIdOrName = props?.business ?? props?.businessId
  const query = useQuery({
    queryKey: businessQueryKeys.getBusinesses(businessIdOrName ?? '', props.context, props.join),
    queryFn: fetchBusinesses,
    enabled:
      enabled &&
      ((!!businessIdOrName && !props.join) ||
        (!!businessIdOrName && (props.join || !!props.context)))
  })

  return query
}

const fetchBusinessesByIds = async ({
  queryKey: [{ ids }]
}: QueryFunctionContext<ReturnType<(typeof businessQueryKeys)['getBusinessesByIds']>>) => {
  const apiInstance = getRootStore().service.studio
  const response = await apiInstance?.post(`/b/search`, {
    ids
  })
  return response.data
}

const defaultBusinessByIdsSelector = (data: BusinessData[]) => {
  return keyBy(data, 'id')
}

export const useBusinessByIds = <TResult = BusinessData[]>(
  businessIds: string[],
  select: (data: BusinessData[]) => TResult
) => {
  const query = useQuery({
    queryKey: businessQueryKeys.getBusinessesByIds(businessIds),
    queryFn: fetchBusinessesByIds,
    enabled: !!businessIds.length,
    select: select ?? defaultBusinessByIdsSelector
  })

  return query
}

const fetchBusinessesUser = async () => {
  const apiInstance = getRootStore().service.vatoms
  const response = await apiInstance.get<string[]>('/me/businesses')
  return response.data
}

const fetchBusinessesInventoryUser = async () => {
  const apiInstance = getRootStore().service.network
  const response = await apiInstance.get<{ items: string[] }>('/vatoms/user-inventory/businesses')
  return response.data.items
}

const fetchAllBusinessesUser = async () => {
  const promises = await allSettled([fetchBusinessesUser(), fetchBusinessesInventoryUser()])
  const res = promises.filter(isFulfilled)
  const rej = promises.filter(isRejected)
  if (rej.length > 0) {
    console.error(
      'fetchAllBusinessesUser:',
      rej.map(r => r.reason)
    )
  }

  const queryData = res.flatMap(r => r.value)
  const data = [...new Set(queryData)]
  return data
}

export const useBusinessesUser = () => {
  const query = useQuery({
    queryKey: businessQueryKeys.businessesUser,
    queryFn: fetchAllBusinessesUser
  })
  return query
}

const getPageConfig = (business: BusinessData) => {
  try {
    const isEmbedded = VatomWallet.isEmbedded()
    const sdkPageConfig = VatomWallet.getSDKPageConfig()

    if (!isEmbedded) {
      return business
    }

    if (sdkPageConfig) {
      // @ts-expect-error 2 type from different files. Fix will be putting all types in another library
      business.pageConfig = sdkPageConfig
    } else {
      // @ts-expect-error 2 type from different files. Fix will be putting all types in another library
      business.pageConfig = {} as PageConfig
    }

    console.log('getPageConfig.business', business)

    return business
  } catch (error) {
    console.log('getPageConfig.error: ', error)
    return business
  }
}

export type Community = {
  id: string
  businessId: string
  name: string
  isDefault: boolean
  description: string | null
  icon: string
  isArchived: boolean
}

const fetchBusinessCommunities = async ({
  queryKey: [{ businessId }]
}: QueryFunctionContext<ReturnType<(typeof businessQueryKeys)['getBusinessCommunities']>>) => {
  const apiInstance = getRootStore().service.events
  const response = await apiInstance?.get<Community[]>(`/b/${businessId}/communities`)

  return response?.data
}

export type UseCommunitiesOptions<T> = Omit<
  UseQueryOptions<
    Community[],
    unknown,
    T,
    ReturnType<typeof businessQueryKeys.getBusinessCommunities>
  >,
  'queryKey' | 'queryFn'
>

export const useBusinessCommunities = <T = Community[]>(
  businessId: string,
  options?: UseCommunitiesOptions<T>
) => {
  const query = useQuery({
    queryKey: businessQueryKeys.getBusinessCommunities(businessId),
    queryFn: fetchBusinessCommunities,
    enabled: !!businessId,
    refetchOnWindowFocus: false,
    ...options
  })
  return query
}

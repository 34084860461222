const AnalyticsEvents = {
  SCREEN_VIEW: 'screenView',
  CLICK: 'click',
  // PAGE_VIEW: 'pageView',
  OPEN_BUSINESS: 'openBusiness',
  OPEN_BUSINESS_WEB_VIEW: 'openBusiness-webView',
  CLICK: 'click'
} as const

export { AnalyticsEvents }

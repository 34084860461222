import type { CSSProperties } from 'react'
import type { FindTokenParams, VatomProviderRequestPayload } from '@vatom/wallet-routes'

export type PlatformOSType = 'ios' | 'android' | 'macos' | 'windows' | 'web' | 'native'

export const tabRoutes = {
  Home: 'Home',
  Wallet: 'Wallet',
  Map: 'Map',
  MapAr: 'MapAr',
  Connect: 'Connect'
} as const

export type tabRoute = (typeof tabRoutes)[keyof typeof tabRoutes]

export enum ThemesEnum {
  light = 'light',
  dark = 'dark',
  system = 'system',
  custom = 'custom'
}

/**
 * key: message.type
 * value: message.payload
 */
export interface MessageList {
  WEB_VIEW_READY: boolean
  GET_USER: UserType
  GET_USER_INFO?: any
  GET_BUSINESS: BusinessType
  GET_BUSINESS_TOKENS?: PublicTokenType[]
  HISTORY_CHANGE: string
  GET_CAMPAIGN_USER_INFO?: any
  UPDATE_CAMPAIGN_USER_INFO: any
  POST_HEADER_MESSAGE: PostHeaderMessagePayload
  GET_PLATAFORM_OS_TYPE: PlatformOSType
  SEND_ANALYTICS: AnalyticsPayload
  NAVIGATE_TO_EXTERNAL_BROWSER: {
    url: string
  }
  SHARE: ShareOptions
  SHARE_RESPONSE: ShareOpenResult
  SHARE_ERROR: any
  /**
   * Site requests the app to do a native goBack()
   */
  NATIVE_GO_BACK?: undefined
  /**
   * App tells the site that the back button has been pressed if a subscription exists
   */
  BACK_BUTTON_PRESSED: undefined
  /**
   * site tells the app that a subscription has been created
   */
  SUBSCRIBE_GO_BACK: undefined
  /**
   * site tells the app that the subscription has been removed
   */
  UNSUBSCRIBE_GO_BACK: undefined
  /**
   * site tells the app that open space or call space
   */
  OPEN_SPACE: SpacePayload
  CALL_SPACE: SpacePayload
  /**
   * site tells the app to navigate to wallet
   */
  NAVIGATE_TO_WALLET: undefined
  /**
   * app tells the site to go back
   */
  SITE_GO_BACK: undefined
  /**
   * site tells the app to go to any token
   */
  NAVIGATE_TO_TOKEN: {
    tokenId: string
    businessIdOrName: string
  }
  NAVIGATE_TO_FIND_TOKEN: {
    objectDefinitionId: string
    campaignId: string
    businessIdOrName: string
  }
  /**
   * app tells the site what are the business tabs
   */
  GET_BUSINESS_TABS: tabRoute[]
  /**
   * site tells the navigate to any tab from business
   */
  NAVIGATE_TO_CONNECT_TAB: navigateTap
  /**
   * site tells the app to navigate to user profiñe
   */
  NAVIGATE_TO_USER_PROFILE: { userId: string }

  GET_POINTS_SUCCESS: {
    campaignId: string
    data: {
      [key in string]?: number | undefined
    }
  }
  GET_POINTS_ERROR: {
    campaignId: string
    error: unknown
  }
  GET_POINTS:
    | undefined
    | {
        campaignId: string
      }
  CLAIM_TOKEN: {
    businessId: string
    objectDefinitionId?: string
    campaignId?: string
    claimLink?: string
    sync?: boolean
  }
  NAVIGATE_TO_HOME: undefined
  SCAN_QR_CODE: undefined
  WINDOW_SCROLL: undefined
  GET_LAYOUT_HIDDEN: boolean
  FETCH_VATOM_API: {
    id: string
    url: string
    method: string
    headers?: Record<string, string>
    body?: string
  }
  FETCH_VATOM_API_SUCCESS: {
    id: string
    data: any
  }
  FETCH_VATOM_API_ERROR: {
    id: string
    error: string
  }
  VATOM_PROVIDER_RESULT: {
    jsonrpc: string
    method: string
    results: any
    id: string
  }
  VATOM_PROVIDER_REQUEST: VatomProviderRequestPayload
  HEADER_DISPLAY: {
    visible: boolean
  }

  LIST_BUSINESSS_CAMPAINGS_GROUPS: {
    businessId: string
  }

  LIST_BUSINESSS_CAMPAINGS_GROUPS_SUCCESS: {
    businessId: string
    data: Campaign[]
  }

  LIST_BUSINESSS_CAMPAINGS_GROUPS_ERROR: {
    businessId: string
    error: string
  }

  SEARCH_OBJECT_DEFINITIONS: {
    businessId: string
    campaignId: string
    payload?: ObjectDefinitionSearchParams
  }

  SEARCH_OBJECT_DEFINITIONS_SUCCESS: {
    businessId: string
    data: ObjectDefinition[]
  }

  SEARCH_OBJECT_DEFINITIONS_ERROR: {
    businessId: string
    error: string
  }
  PERFORM_ACTION: {
    tokenId: string
    action: string
    payload?: any
  }
  PERFORM_ACTION_SUCCESS: {
    response: any
  }
  PERFORM_ACTION_ERROR: {
    error: string
  }
  MESSAGE_EMITTED: {
    payload?: any
  }
}

export type ObjectDefinitionSearchParams = {
  displayName?: string
  description?: string
  designValues?: any
  blueprintValues?: any
}

export type navigateTap = {
  tab?: tabRoute
  communityId?: string
  roomId?: string
  roomTab?: string
  spaceId?: string
}
export type MessageTypes = keyof MessageList

export type IPostMessage<T extends MessageTypes> = MessageList[T] extends undefined
  ? {
      type: T
      payload?: MessageList[T]
      data?: string
    }
  : {
      type: T
      payload: MessageList[T]
      data?: string
    }

export type RNMessage = WebViewMessageEvent['nativeEvent']

export interface WebViewMessageDataOverride<T extends MessageTypes>
  extends Omit<RNMessage, 'data'> {
  data: IPostMessage<T>
}

export interface ExperienceTypes {
  analytics: { event: (type: string, payload: any) => void }
  postHeaderMessage: (headerTittle: string) => void
  navigateToExternalBrowser: (url: string) => void
  sendEmail: (emailAddress: string) => void
  openSpace: (alias: string) => void
  callSpace: (alias: string) => void
  navigateToWallet: () => void
  navigateToNFTDetail: (tokenId: string, businessIdOrName: string) => void
  navigateToFindToken: (payload: FindTokenParams) => void
  layoutHidden: boolean
  displayHeader: (visible: boolean) => void
}

export type SpacePayload = {
  alias: string
}

const AnalyticsEvents = {
  SCREEN_VIEW: 'screenView',
  CLICK: 'click',
  // PAGE_VIEW: 'pageView',
  OPEN_BUSINESS: 'openBusiness',
  OPEN_BUSINESS_WEB_VIEW: 'openBusiness-webView'
} as const

type EventType = keyof typeof AnalyticsEvents

export interface AnalyticsPayload {
  type: EventType
  payload?: any
}

/**
 * *
 * Business
 */
export type PageConfigInterface = {
  showHomePageIcon?: boolean
  icon?: string
  theme?: {
    header: {
      logo:
        | string
        | {
            src: string
            style: CSSProperties
          }
    }
    iconTitle?: CSSProperties
    emptyContainer?: CSSProperties
    icon?: CSSProperties
    main: CSSProperties
    emptyState: CSSProperties
    pointsHeader?: CSSProperties
    pageTheme?: ThemesEnum
    customTheme?: any
  }
  text?: {
    emptyState: string
  }
  features?: {
    hideNavigation?: boolean
    inventory?: {
      hideHeader?: boolean
    }
    businessHeader?: {
      title?: boolean
      hideHeader?: boolean
    }
    footer?: {
      icons?: [
        {
          link?: string
          src?: string
          title?: string
          id?: string
        }
      ]
      enabled?: boolean
    }
    vatom?: {
      autoOpen?: string[]
      disableNewTokenToast?: boolean
      ignoreNewTokenToast?: string[]
      pinned?: string[]
      hideTokenActions?: boolean
    }
    pointsHeader?: {
      channel?: string
      enabled?: boolean
      position?: string
      title?: string
      type?: string
    }
    icon?: {
      badges?: boolean
      editions?: boolean
      titles?: boolean
    }
    scanner?: {
      enabled?: boolean
    }
  }
}

export type BusinessType = {
  id: string
  name: string
  displayName: string
  logoSrc: string
  createdAt: number
  pageConfig: PageConfigInterface
  defaultCampaignId: string
}

/**
 * *
 * User
 */
export type UserType = {
  bio: string
  default_business_id: string
  default_space_id: string
  email: string
  email_verified: boolean
  location: LocationType
  name: string
  phone_number: string
  phone_number_verified: boolean
  picture: string
  sub: string
  updated_at: number
  wallet_address: string
  website: string
  guest: boolean
}

export type LocationType = {
  country: string
  latitude: number
  locality: string
  longitude: number
  postal_code: string
  region: string
}

export type PostHeaderMessagePayload = {
  title: string | null
}

export interface PointsPayload {
  entries: string
  total: string
}

/**
 * UseUser
 */
export type UserProviderTypes<CampaignUserInfo = unknown, UserBusinessInfo = unknown> = {
  user?: UserType
  campaignUserInfo?: CampaignUserInfo
  userBusinessInfo?: UserBusinessInfo
  updateUserCampaignInfo: (campaignUserInfo: CampaignUserInfo) => void
  getUserBusinessInfo: () => void
}

/**
 * UseBusiness
 */
export type BusinessProviderTypes = {
  business?: BusinessType
  tokens?: PublicTokenType[]
  isLoadingTokens: boolean
  findToken: (options: FindTokenOptions) => void
  claimToken: (options: ClaimTokenOptions) => void
}

/**
 * token type, TODO: validate format
 */

export type attributeType = {
  trait_type: string
  value: string | number
}

export type metadataType = {
  animation_url: string | undefined
  attributes: attributeType[]
  background_color: string | undefined
  contract_data: string | undefined
  description?: string
  external_uri?: string
  image?: string
  name?: string
  youtube_url: string | undefined
}

export type studioInfoType = {
  blueprintId: string
  businessId: string
  campaignId: string
  objectDefinitionId: string
}

export type tokenPositionType = {
  coordinates: number[]
  type: string
}

export type PublicTokenType = {
  tokenId: string
  metadata: metadataType
  studioInfo: studioInfoType
  position: tokenPositionType | undefined
}

export interface ShareSingleResult {
  message: string
  success: boolean
}
export interface ShareOpenResult extends ShareSingleResult {
  dismissedAction?: boolean
}

export interface ActivityItemSource {
  placeholderItem: ActivityItem
  item: {
    [key in ActivityType]?: ActivityItem | null | undefined
  }
  subject?: {
    [key in ActivityType]?: string
  }
  dataTypeIdentifier?: {
    [key in ActivityType]?: string
  }
  thumbnailImage?: {
    [key in ActivityType]?: string
  }
  linkMetadata?: LinkMetadata
}

export interface LinkMetadata {
  originalUrl?: string
  url?: string
  title?: string
  icon?: string
  image?: string
  remoteVideoUrl?: string
  video?: string
}

export interface ActivityItem {
  type: 'text' | 'url'
  content: string
}

export declare type ActivityType =
  | 'default'
  | 'addToReadingList'
  | 'airDrop'
  | 'assignToContact'
  | 'copyToPasteBoard'
  | 'mail'
  | 'message'
  | 'openInIBooks'
  | 'postToFacebook'
  | 'postToFlickr'
  | 'postToTencentWeibo'
  | 'postToTwitter'
  | 'postToVimeo'
  | 'postToWeibo'
  | 'print'
  | 'saveToCameraRoll'
  | 'markupAsPDF'

export interface ShareOptions {
  message?: string
  title?: string
  url?: string
  urls?: string[]
  type?: string
  subject?: string
  email?: string
  recipient?: string
  excludedActivityTypes?: ActivityType[] | string[]
  failOnCancel?: boolean
  showAppsToView?: boolean
  filename?: string
  filenames?: string[]
  saveToFiles?: boolean
  activityItemSources?: any
  isNewTask?: boolean
}

type NodeHandle = number

export interface WebViewMessage extends WebViewNativeEvent {
  data: string
}

export interface WebViewNativeEvent {
  url: string
  loading: boolean
  title: string
  canGoBack: boolean
  canGoForward: boolean
  lockIdentifier: number
}

// Similar to React.SyntheticEvent except for nativeEvent
export interface NativeSyntheticEvent<T>
  extends React.BaseSyntheticEvent<T, NodeHandle, NodeHandle> {}

export declare type WebViewMessageEvent = NativeSyntheticEvent<WebViewMessage>

export type BusinessNavigation = {
  tabs: tabRoute[]
  canNavigate: (route: string) => boolean
  navigate: (params: navigateTap) => void
  navigateToUserprofile: (userId: string) => void
}
export type Points = {
  [key in string]?: number | undefined
}

type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>
  }[Keys]

interface FindTokenProps {
  objectDefinitionId?: string
  objectDefinitionIds?: string[]
  campaignId?: string
  claimLink?: string
  autoClaim?: boolean
  sync?: boolean
}

interface ClaimTokenProps {
  objectDefinitionId?: string
  campaignId?: string
  claimLink?: string
  sync?: boolean
}

export type FindTokenOptions = RequireAtLeastOne<
  FindTokenProps,
  'objectDefinitionId' | 'objectDefinitionIds' | 'campaignId'
>

export type ClaimTokenOptions = RequireAtLeastOne<
  ClaimTokenProps,
  'campaignId' | 'objectDefinitionId'
>

export type Campaign = {
  id: string
  groupCampaignId: string
  revision: number
  numRevisions: number
  displayName: string
  startDate: string | null
  startDateTimezone: string | null
  endDate: string | null
  endDateTimezone: string | null
  createdAt: string
  updatedAt: string
  stage: string
  isRevision: boolean
  businessId: string
  viewerId: string
  cover: Cover
  uri: string
  budget: number | null
  wsUrl: string
  wizardId: string | null
  userWizardId: string | null
  type: string
  createdBy: string
  obejctDefinitions: ObjectDefinition[]
}

export type Cover = {
  type: string
  url: string
}

export type ObjectDefinition = {
  id: string
  uri: string
  displayName: string
  description: string
  stage: string
  category: string
  cover: Cover
  createdAt: string
  updatedAt: string
  testProviderInfo: any
  designId: string
  designName?: string
  businessId: string
  blueprintValues: Record<string, unknown>
  designValues: DesignValues
  blueprintId: string
  blueprintName: string
}

export type DesignValues = {
  [key: string]: Placeholder
}

export type Placeholder = {
  uri: string
  config: Config
}

export type Config = {
  states?: Array<State>
  image?: {
    ref: string
    type: string
  }
}

export type State = {
  image: {
    type: string
    ref: string
  }
  name: string
  rule: Record<string, unknown>
}

import type { ViewMode } from '@vatom/sdk/core'
import { Token } from '@vatom/sdk/core'
import type { Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree'
import { getParent, types } from 'mobx-state-tree'

import { type InventoryRegionType } from '../regions/VatomInventoryRegion'

type KnownActionTypes = 'Drop' | 'Pickup' | 'Transfer' | 'Delete' | 'Clone' | 'custom'

const State = types.model('State', {
  'studio-info-v1': types.maybe(
    types.model('StudioInfoV1', {
      businessId: types.string,
      campaignId: types.string,
      objectDefinitionId: types.string
    })
  ),
  'map-ready-v1': types.maybe(
    types.model('MapReadyV1', {
      location: types.maybe(
        types.model('Location', {
          lat: types.number,
          lon: types.number
        })
      ),
      businessId: types.maybe(types.string),
      campaignId: types.maybe(types.string),
      objectDefinitionId: types.maybe(types.string)
    })
  ),
  'meta-v1': types.maybe(
    types.model('MetaV1', {
      displayName: types.string,
      description: types.string,
      category: types.maybeNull(types.string),
      isDraft: types.boolean
    })
  ),
  'map-ready-poi-business-v1': types.maybe(
    types.model({
      hoursText: types.maybe(types.string)
    })
  ),
  'link-v1': types.maybe(
    types.model({
      location: types.maybe(types.string),
      blank: types.maybe(types.boolean)
    })
  ),
  'pepsi-store-prizes-v1': types.maybe(
    types.model({
      numPrizesAvailable: types.maybe(types.number)
    })
  ),
  'gft-reward-v1': types.maybe(
    types.model({
      gftCampaignId: types.maybe(types.string),
      gftEnvironment: types.maybe(types.string),
      couponId: types.maybe(types.string),
      status: types.maybe(types.string)
    })
  ),
  'transferable-v1': types.maybe(types.model({})),
  'cloneable-v1': types.maybe(
    types.model({
      cloningScore: types.number,
      numDirectClones: types.number
    })
  ),
  'gft-redeemable-v1': types.maybe(types.model({})),
  'quiz-v2': types.maybe(
    types.model({
      answerOptions: types.optional(types.array(types.string), []),
      clue: types.maybe(types.string),
      lastPlayed: types.maybe(types.string),
      question: types.maybe(types.string),
      status: types.maybe(types.string),
      solved: types.maybe(types.boolean)
    })
  ),
  'poll-v1': types.maybe(
    types.model({
      lastReplied: types.maybe(types.string),
      question: types.maybe(types.string),
      response: types.maybe(types.string),
      responseOptions: types.optional(types.array(types.string), []),
      results: types.maybe(types.frozen<Record<string, string>>())
    })
  ),
  'game-of-chance-v1': types.maybe(
    //
    types.model({
      error: types.maybe(
        types.model({
          code: types.maybe(types.number),
          message: types.maybe(types.string)
        })
      ),
      lastSentPrizeAt: types.maybe(types.string),
      maxPlaysPerPeriod: types.maybe(types.number),
      playPeriod: types.maybe(types.string),
      playPeriodTimezone: types.maybe(types.string),
      prizeDescription: types.maybe(types.string),
      prizeIcon: types.maybe(types.string),
      prizeName: types.maybe(types.string),
      prizeToken: types.maybe(types.string),
      singleUse: types.maybe(types.boolean),
      prizeExchange: types.maybe(types.boolean),
      playCount: types.maybe(types.number),
      prizeSet: types.maybe(
        types.array(
          types.model('PrizeSet', {
            prizeDescription: types.maybe(types.string),
            prizeIcon: types.maybe(types.string),
            prizeName: types.maybe(types.string)
          })
        )
      )
    })
  ),
  'coupon-v1': types.maybe(
    types.model({
      availableToGuestUser: types.maybe(types.boolean),
      code: types.maybe(types.string),
      couponUrl: types.maybe(types.string),
      enabled: types.maybe(types.boolean),
      expiry: types.maybe(types.string),
      id: types.maybe(types.string),
      pin: types.maybe(types.string),
      rule: types.maybe(types.string),
      value: types.maybe(types.string),
      provider: types.maybe(
        types.model({
          providerId: types.maybe(types.string)
        })
      )
    })
  ),
  'form-v1': types.maybe(
    types.model({
      data: types.maybe(
        types.model({
          Name: types.maybe(types.string)
        })
      ),
      dataAsString: types.maybe(types.string),
      globalResponseLimit: types.maybe(types.number),
      responseCount: types.maybe(types.number),
      submitted: types.maybe(types.boolean),
      submitted_at: types.maybe(types.string)
    })
  ),
  'honda-reward-v1': types.maybe(
    types.model({
      redeemed: types.maybe(types.boolean),
      redeemedAt: types.maybe(types.string)
    })
  )
})

const ViewConfigImageInner = types.model('ViewConfigImageInner', {
  type: types.string,
  ref: types.string
})
const ViewConfigImage = types.model('ViewConfigImage', {
  image: ViewConfigImageInner
})

const ViewConfigStates = types.model('ViewConfigStates', {
  states: types.array(
    types.model('ViewConfigState', {
      image: ViewConfigImageInner,
      name: types.string,
      rule: types.model('ViewConfigRule', {})
    })
  )
})

const ImageV1 = types.model({
  id: 'image-v1',
  entrypoint: types.string,
  config: ViewConfigImage
})

const DynamicImageV1 = types.model({
  id: 'dynamic-image-v1',
  entrypoint: types.string,
  config: ViewConfigStates
})

const ThreeDV1 = types.model({
  id: '3d-v1',
  entrypoint: types.string,
  config: types.model('ThreeDV1Config', {
    scene: types.model('ThreeDV1Scene', {
      ref: types.string,
      type: types.string
    })
  })
})

const CardView1 = types.model({
  id: types.string,
  entrypoint: types.string,
  config: types.frozen()
})

const ViewPlacementModel = types.union(CardView1, ImageV1, DynamicImageV1, ThreeDV1)

export type ViewPlacementModelType = Instance<typeof ViewPlacementModel>

const ViewPlacement = types.model('ViewPlacement', {
  placeholder: types.model('ViewPlacementPlaceholder', {
    id: types.string
  }),
  view: ViewPlacementModel
})
export type ViewPlacement = Instance<typeof ViewPlacement>

export const getCardView = (
  viewPlacements: Instance<typeof ViewPlacement>[]
): Instance<typeof CardView1> | null => {
  const cardView = viewPlacements.find(viewPlacement => viewPlacement.placeholder.id === 'card-v1')

  if (!cardView) {
    return null
  }

  if (!CardView1.is(cardView.view)) {
    return null
  }

  return cardView.view
}

// placeholder > id > icon-v1
// view > dynamic-image-v1
// placeholder > id > card-v1
// view > id > objectId (string)
// placeholder > id > background-v1
// view > image-v1
// placeholder > id > icon-v1
// view > image-v1

export function isImageV1(view: ViewPlacementModelType): view is Instance<typeof ImageV1> {
  return view.id === 'image-v1'
}
export function isDynamicImageV1(
  view: ViewPlacementModelType
): view is Instance<typeof DynamicImageV1> {
  return view.id === 'dynamic-image-v1'
}

export function isThreeDView(view: ViewPlacementModelType): view is Instance<typeof ThreeDV1> {
  return view.id === '3d-v1'
}

export const VatomModel = Token.named('VatomModel')
  .props({
    id: types.string,
    type: types.string,
    parentId: types.string,
    owner: types.string,
    author: types.string,
    lastOwner: types.maybe(types.string),
    state: State,
    viewPlacements: types.array(ViewPlacement)
  })
  .views(self => ({
    get threeDInfo(): {
      url: string
      autoRotate?: boolean
    } | null {
      const backgroundv1 = self.viewPlacements.find(
        viewPlacement => viewPlacement.placeholder.id === 'background-v1'
      )
      if (backgroundv1 && isThreeDView(backgroundv1.view)) {
        return {
          url: backgroundv1.view.config.scene.ref
        }
      }
      return null
    },
    get displayImage() {
      let iconImageUrl = 'https://fakeimg.pl/100x100/'
      const icon = self.viewPlacements.find(
        viewPlacement => viewPlacement.placeholder.id === 'icon-v1'
      )
      if (icon && isImageV1(icon?.view)) {
        iconImageUrl = icon.view.config.image.ref
      } else if (icon && isDynamicImageV1(icon?.view)) {
        const dynamicDefaultState = icon.view.config.states?.find(state => state.name === 'Default')
        if (dynamicDefaultState?.image.ref) {
          iconImageUrl = dynamicDefaultState?.image.ref
        }
      }
      return iconImageUrl
    },
    get studioInfo() {
      return self.state['studio-info-v1']
    },
    get actions(): string[] {
      return []
    },
    get properties() {
      return {
        template: ''
      }
    },
    getResource(name: string) {
      if (!name) return

      // const resource = self.viewPlacements.find(
      //   viewPlacement => viewPlacement.view.config.resource.type === name
      // )
      return null // resource?.view.config.resource.ref
    }
  }))
  .views(self => ({
    get position() {
      const map = self.state['map-ready-v1']
      if (!map || !map.location) {
        return null
      }
      const { lon, lat } = map.location
      return {
        coordinates: [lon, lat]
      }
    },
    get metadata() {
      // "displayName": "Canjea tu código",
      // "description": "Muestra el código que recibiste para canje para que tu tendero lo valide y disfrutes de tu álbum",
      // "category": null,
      // "isDraft": true
      const meta = self.state['meta-v1']

      return {
        name: meta?.displayName ?? '',
        description: meta?.description ?? '',
        image: self.displayImage,
        category: meta?.category ?? ''
      }
    }
  }))
  .views(self => ({
    get IconFace() {
      return 'https://fakeimg.pl/100x100/'
    },
    get engagedFaceId() {
      const backgroundv1 = self.viewPlacements.find(
        viewPlacement => viewPlacement.placeholder.id === 'background-v1'
      )
      if (backgroundv1 && isThreeDView(backgroundv1.view)) {
        return 'ThreeDFace'
      }

      return 'ImageFace'
    }
  }))
  .views(self => ({
    getFaceId(view: ViewMode) {
      if (view === 'engaged') {
        return self.engagedFaceId
      }

      const viewPlacements = self.viewPlacements

      if (!viewPlacements) {
        return 'ImageFace'
      }

      const cardViewV1 = viewPlacements.find(view => view.placeholder.id === 'card-v1')

      if (cardViewV1) {
        const isCardViewV1 = CardView1.is(cardViewV1.view)

        if (isCardViewV1) {
          return 'WebFace' as const
        }
      }

      return 'ImageFace'
    },
    get hasCardView() {
      return false
    },
    get actions(): Array<KnownActionTypes> {
      const stateKeys = Object.keys(self.state)
      const result: Array<KnownActionTypes> = ['Delete']

      if (stateKeys.includes('map-ready-v1')) {
        const map = self.state['map-ready-v1']
        if (map?.location) {
          result.push('Pickup')
        } else {
          result.push('Drop')
        }
      }

      if (stateKeys.includes('transferable-v1')) {
        result.push('Transfer')
      }
      if (stateKeys.includes('cloneable-v1')) {
        result.push('Clone')
      }

      return result
    },
    async performAction(action: KnownActionTypes, payload?: any, extra?: any): Promise<any> {
      const region = getParent(self, 2) as InventoryRegionType
      console.log('performAction', !!region, action, payload, extra)
      console.log('inventoryApi', region.inventoryApi)
      try {
        if (action === 'Transfer') {
          await region.inventoryApi.post(`/vatoms/${self.id}/execute-action`, {
            behaviorId: 'transferable-v1',
            actionId: 'transfer-v1',
            parameters: {
              recipientUserId: extra.userProfile.id
            }
          })
        }

        if (action === 'Pickup') {
          await region.inventoryApi.post(`/vatoms/${self.id}/execute-action`, {
            behaviorId: 'map-ready-v1',
            actionId: 'pickup-v1'
          })
        }

        if (action === 'Drop') {
          /** payload
         {
        'geo.pos': { Lat: coords.latitude, Lon: coords.longitude }
            }
         */
          await region.inventoryApi.post(`/vatoms/${self.id}/execute-action`, {
            behaviorId: 'map-ready-v1',
            actionId: 'drop-v1',
            parameters: {
              location: {
                lat: payload['geo.pos'].Lat,
                // lng: payload['geo.pos'].Lon,
                lon: payload['geo.pos'].Lon
              }
            }
          })
        }

        if (action === 'Delete') {
          await region.inventoryApi.delete(`/vatoms/${self.id}`)
        }

        if (action === 'Clone') {
          await region.inventoryApi.post(`/vatoms/${self.id}/execute-action`, {
            behaviorId: 'cloneable-v1',
            actionId: 'clone-v1',
            parameters: {
              cloneOwnerUserId: extra.userProfile.id
            }
          })
        }
        if (action === 'custom') {
          return await region.inventoryApi.post(`/vatoms/${self.id}/execute-action`, payload)
        } else {
          console.warn('VatomModel.performAction: Unhandled action!', action, payload)
        }
      } catch (error) {
        console.error('Error performing action', error)
        // @ts-ignore
        // if (error?.response?.data) {
        //   // TODO: handle errors gracefully for all actions
        //   // @ts-ignore
        //   const errorMessage = error.response.data?.error?.message ?? 'Vatom must be cloneable'
        //   const _e = new Error(errorMessage)
        //   _e.name = 'Clone Vatom'
        //   throw _e
        // }
        throw error
      } finally {
        region.reload()
      }
    }
  }))
  .views(self => ({
    getFace(view: ViewMode) {
      if (self.getFaceId(view) === 'WebFace') {
        // TODO: get info from ===> console.log('NENE cardViewV1',{ placeHolder: { ...cardViewV1?.placeholder } },{ view: { ...cardViewV1?.view } })

        return {
          id: 'test-id-123',
          properties: {
            display_url: 'http://localhost:3001/',
            parsedConfig: {}
          }
        }
      }

      console.error('View Mode not implemented', view, self.id)
      throw new Error('View Mode not implemented')
    }
  }))

export type VatomModelType = Instance<typeof VatomModel>
export type VatomModelSnapshotOut = SnapshotOut<typeof VatomModel>
export type VatomModelSnapshotIn = SnapshotIn<typeof VatomModel>
